<template>
    <div class="d-flex">
        <!-- 左侧 -->
        <div v-if="!fullscreen">
            <div  class="leftClass">
                <div class="w-224 bg_fff " style="height: 100vh;position: relative;">
                    <div class="text-cen p-b-32">
                        <img src="../assets/image/bg_3.png" class="wh-85 m-t-57" alt="">
                        <div class="f-s-32 co_092DB8 m-t-18">YAAAS TEA</div>
                    </div>

                    <div class="h-178  dd-flex cup" :class="tabIndex==i?'bg_F0F6FF':'bg_fff'" @click="tabClcik(i)" :key="i" v-for="(item,i) in leftList">
                        <div class="text-cen">
                            <img :src="require(`../assets/image/bg_${i+3}.png`)" class="wh-46" alt="">
                            <div class="f-s-30 ">{{ item.name }}</div>
                        </div>
                    </div>
                    <div class="dd-flex logutClass cup" @click="goToAbout">
                        <img src="../assets/image/bg_8.png" class="wh-32" alt="">
                        <div class="m-l-14 f-s-30">Exit</div>
                    </div>
                </div>
            </div>
            <div class="w-224"></div>
        </div>
       <div class="flex1">
         <!-- 顶部 -->
         <div>
            <div class="topClass">
                <div class="h-151  d-flex-cen flex1">
                    <div class="d-flex-cen bg_fff ju-sp flex1 h-151 p-r-28" :class="!fullscreen?'m-l-226':''" v-if="!fullscreen">
                        <div class="bold m-l-16">
                            <div class="f-s-36 co_092DB8">Digital Store Production Management System</div>
                            <div class="f-s-28 co_111 m-t-5">Chinatown Branch</div>
                        </div>
                        <div>
                            <div class="countdown-footer f-s-32 co_111">{{ usTime }}</div>
                        </div>
                        <div class="d-flex-cen">
                            <!-- userInfo.avatar ||  -->
                            <img src="../assets/image/bg_7.png" class="m-l-56 wh-57" alt="">
                            <div class="m-l-14 f-s-32 ">{{userInfo.nickname}}</div>
                            <img src="../assets/image/bg_11.png" @click="toggleFullScreen" class="m-l-56 wh-36" alt="">
                        </div>
                    </div>
                    <div class="d-flex-cen h-151 ju-sp bg_fff flex1" v-else>
                        <div></div>
                        <div class="f-s-57 bold">2024-10-10 10:26</div>
                        <img src="../assets/image/bg_11.png" @click="toggleFullScreen" class="m-r-56 wh-36" alt="">
                    </div>
                </div>
            </div>
            <div class="h-151"></div>
         </div>

        <!-- 中间 -->
        <div>
            <!-- 工作台 -->
            <div class="" v-if="tabIndex == 0" :style="{'margin':fullscreen?'18px 0 18px 7px':'18px 0 18px 18px'}">
                <div class=" d-flex  m-b-39">
                    <div class="bg_fff " :class="[fullscreen?'w-370':'w-402',fullscreen && i == 4?'':'m-r-13']" :key="i" v-for="(item,i) in workbenchesList">
                        <div class="f-s-36 p-t-28 bold text-cen">{{ item.title }}</div>
                        <div class="m-t-7 d-flex-cen ju-sa">
                            <div class="h-94 dd-flex" :key="k" v-for="(item3,k) in item.times">
                                <div class="text-cen" >
                                    <div class=" co_092DB8 bold f-s-28" v-if="item.times_index == k">{{item3}}</div>
                                    <div class="co_444 text_del bold f-s-28" v-else-if="item.times_index > k">{{item3}}</div>
                                    <div class="co_111 bold f-s-28" v-else>{{item3}}</div>
                                    <!-- <div class="f-s-21 co_777 m-t-4">{{item.conter}}</div> -->
                                </div>
                            </div>
                        </div>
                        <div class="m-t-18 ">
                            <div class=" bg_F0F6FF m-auto m-b-14 box_box por" :class="fullscreen?'w-341':'w-380'" style="padding:18px 12px" :key="i" v-for="(item2,i) in item.step">
                                <div class="f-s-32 bold">{{item2.title}}</div>
                                <div class="co_777 f-s-25 m-t-7">{{item2.min}}/{{ item2.force_min }}</div>
                                <div class="f-s-27 co_444 m-t-14">{{ item2.tips }}</div>

                                <div class="poa timeClass">
                                    <img src="../assets/image/bg_10.png" v-if="item2.state==1" class="wh-43" alt="">
                                    <img src="../assets/image/bg_9.png" v-if="item2.state==2" class="wh-43" alt="">
                                </div>
                                <div class="poa" style="top: 60px;right: 0;" v-if="item2.state==2" @click="restartClick(item.times[item.times_index],item2.id)"><el-button type="warning">restart</el-button></div>
                            </div>
                        </div>
                        <div class="d-flex-cen ju-sa p-b-32">
                            <div class="nextClass f-s-28 dd-flex co_092DB8 bold cup" @click="workGetClick(item.times[item.times_index],item.step)">NEXT</div>
                            <div class="finishClass f-s-28 dd-flex co_fff bold cup" @click="workEndSublit(item.times[item.times_index],item.step)">FINISH</div>
                        </div>
                    </div>
                </div>
                <div class="dd-flex"><el-pagination background size="large" @current-change="currentChange" prev-text="上一页" :page-size="fullscreen?5:4" next-text="下一页" prev-icon="ArrowLeftBold"  layout="prev, pager, next" :total="total" /></div>
            </div>

            <!-- 锅具管理 -->
            <div class="m-18 bg_fff" v-if="tabIndex == 1" style="margin-bottom: 0 !important;">
                <el-row >
                    <el-col :span="14">
                        <div class="bg_fff box_box" >
                            <div class="h-121 d-flex-cen bg_fff" style="border-bottom: 2px solid #F5F6FA;">
                                <div class="f-s-36 bold m-l-28">Pots LIst</div>
                                <img src="../assets/image/bg_12.png" @click="addPot" class="w-126 h-53 m-l-32 cup" alt="">
                            </div>
                            <div class="m-t-2 bg_fff p-t-32 p-l-20 p-r-20 box_box d-flex flex-w ju-sp">
                                <div class="w-452 bg_F9F9F9 border-r-14  m-b-18 box_box" :class="potObj.id == item.id?'onPotClass':''" :key="i" v-for="(item,i) in potList">
                                <div class="p-l-14 p-r-14" :style="{'border-bottom': potObj.id == item.id ?'1px solid #5C82FF':'2px solid #EEEEEE'}">
                                        <div class="d-flex-cen ju-sp p-t-20 ">
                                            <div class="f-s-32 bold">{{item.title}}</div>
                                            <el-switch size="large" :active-value="1" :inactive-value="0" v-model="item.switch" />
                                        </div>
                                        <div class="m-t-16 co_333 f-s-25">TIME：<span class=" co_666">{{item.times.toString()}}</span></div>
                                        <div class="d-flex-cen ju-sp p-b-18 m-t-16" >
                                            <div class=" co_333 f-s-25">Step：<span class=" co_666">{{ item.count }}</span></div>
                                            <div class=" co_333 f-s-25">Total time：<span class=" co_666">{{item.min}}</span></div>
                                        </div>
                                </div>
                                <div class="f-s-28 bold  d-flex-cen cup">
                                        <div class="w-226 dd-flex h-80 co_333" @click="potInfoClick(item.id)" :style="{'border-right': potObj.id == item.id ?'1px solid #5C82FF':'2px solid #EEEEEE'}">View/Edit</div>
                                        <div class="w-226 dd-flex h-80 co_FF4040" @click="potDeletClick(item.id)">Delet</div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="10">
                        <div class=" bg_fff " style="border-left: 1px solid #F5F6FA;">
                            <div class="f-s-36 co_111 bold h-121 d-flex-cen p-l-28">Information</div>
                            <div class="d-flex-cen bg_F0F6FF ju-sp h-82 p-l-28 p-r-28">
                               <div class="d-flex-cen">
                                    <img src="../assets/image/bg_13.png" class="wh-32" alt="">
                                    <div class="f-s-28 co_111 m-l-5 bold">Basic Information</div>
                               </div>
                                <el-button type="primary" class="w-160 f-s-26" size="large" @click="potSaveClick" round>Pots Save</el-button>
                            </div>
                            <div class="d-flex-cen ju-sp h-96 m-l-28 m-r-28 border-b-F5F6FA">
                                <span class="f-s-28 bold">Pot Name</span>
                                <el-input
                                v-model="potObj.title"
                                class="f-s-28 w-400"
                                placeholder="Please enter the Pot Name"
                                
                                />
                            </div>
                            <div class="d-flex-cen ju-sp m-l-28 m-r-28 p-b-5 box_box border-b-F5F6FA minh_96">
                                <span class="f-s-28 bold">Start Time</span>
                                <div class="d-flex-cen">
                                    <div class="w-280" v-if="potObj.times2?.length > 0">
                                        <el-tag v-for="tag in potObj.times2" @close="tagClose(tag)" size="large" :key="tag" closable class="m-l-10 m-t-5 f-s-25" type="primary">
                                        {{ tag }}
                                        </el-tag>
                                    </div>
                                    <!-- <div class="co_A8ABB9 f-s-28" v-else>Please select a start time</div> -->
                                    <el-time-select
                                    v-model="startTime" 
                                    style="width: 60px;"
                                    class="m-l-20"
                                    @change="timeSelectClick"
                                    start="01:20"
                                    step="00:01"
                                    end="23:30"
                                    :clearable="false"
                                    placeholder="Please select"
                                    >
                                        
                                    </el-time-select>
                                    <!-- <img src="../assets/image/bg_14.png" class="w-14 h-28 m-l-5" alt=""> -->
                                </div>


                               
                            </div>
                            <div class="h-156 p-28 box_box border-b-F5F6FA">
                               <div class="d-flex-cen ju-sp ">
                                <span class="f-s-28 bold">Pot Name</span>
                                <div class="d-flex-cen" v-if="potObj.type != 1">
                                    <div class="co_666" >{{potObj.days2?.length || 0}} Days Selected</div>
                                    <div class="cup">
                                        <el-date-picker
                                        v-model="potObj.days2"
                                        type="dates"
                                        placeholder=""
                                        @change="datePickerChang"
                                        format="DD"
                                        class="w-40 f-s-26 m-l-20"
                                        value-format="DD"
                                        :clearable="false"
                                        />
                                    </div>

                                    <!-- <img src="../assets/image/bg_14.png" class="w-14 h-28 m-l-5" alt=""> -->
                                </div>
                               </div>
                               <div class="m-t-10">
                                <el-radio-group v-model="potObj.type" class="ju-sp d-flex-cen">
                                    <el-radio value="1" class="f-s-28" size="large">Daily</el-radio>
                                    <el-radio value="2" class="f-s-28" size="large">Custom Time</el-radio>
                                </el-radio-group>
                               </div>
                            </div>
                            <div class="d-flex-cen ju-sp h-96 m-l-28 m-r-28 border-b-F5F6FA">
                                <span class="f-s-28 bold">Statue</span>
                                <div class="d-flex-cen">
                                    <div class="m-r-14 f-s-28 co_666">{{ potObj.switch == 1?'Enable':'Closure'}} </div>
                                    <el-switch size="large" :active-value="1" :inactive-value="0" v-model="potObj.switch" />
                                </div>
                            </div>

                            <div class="d-flex-cen bg_F0F6FF ju-sp">
                                <div class="d-flex-cen  h-82 p-l-28 ">
                                    <div class="d-flex-cen">
                                        <img src="../assets/image/bg_13.png" class="wh-32" alt="">
                                        <div class="f-s-28 co_111 m-l-5 bold">Process Settings</div>
                                    </div>
                                </div>
                                <div class="d-flex-cen p-r-28">
                                    <div class="d-flex-cen">
                                        <img src="../assets/image/bg_15.png" class="wh-32 m-r-4" alt="">
                                        <div class="f-s-28 co_666 ">{{ potObj.count }}</div>
                                    </div>
                                    <div class="m-l-28 d-flex-cen">
                                        <img src="../assets/image/bg_16.png" class="wh-32 m-r-4" alt="">
                                        <div class="f-s-28 co_666 ">{{ potObj.min }}</div>
                                    </div>
                                </div>
                            </div>


                            <div class="p-t-18 p-l-14 p-r-14 p-b-18" :key="i" v-for="(item,i) in stepList">
                                <div class=" h-508 border-r-14" style="border:2px solid #EEEEEE">
                                    <div class="f-s-32 co_111 bold text-cen m-t-21 m-b-21">Step{{ i+1 }}</div>
                                    <div class="d-flex-cen ju-sp h-75 m-l-28 m-r-28 border-b-F5F6FA">
                                        <span class="f-s-28 bold">Step Name</span>
                                        <el-input
                                        v-model="item.title"
                                        class="f-s-28 w-300"
                                        placeholder="Please enter"
                                        
                                        />
                                    </div>
                                    <div class=" m-l-28 m-r-28 p-b-18 m-t-18 border-b-F5F6FA">
                                        <span class="f-s-28 bold">Prompt Name</span>
                                        <el-input
                                        v-model="item.tips"
                                        class="f-s-28 m-t-18 textClass"
                                        placeholder="Please enter"
                                        
                                        />
                                    </div>
                                    <div class="d-flex-cen ju-sp h-75 m-l-28 m-r-28 border-b-F5F6FA">
                                        <span class="f-s-28 bold">Duration</span>
                                        <div class="d-flex-cen">
                                            <!-- <div>{{item.min}}</div> -->
                                            <el-time-picker class="f-s-26 w-240"  v-model="item.min" format="mm:ss"
                                            value-format="mm:ss" placeholder="Please select" />
                                            <img src="../assets/image/bg_14.png" class="w-14 h-28 m-l-5" alt="">
                                        </div>
                                    </div>
                                    <div class="d-flex-cen ju-sp h-75 m-l-28 m-r-28 border-b-F5F6FA">
                                        <span class="f-s-28 bold">Forced Time</span>
                                        <div class="d-flex-cen">
                                            <!-- <div>{{item.force_min}}</div> -->
                                            <el-time-picker class="f-s-26 w-240" v-model="item.force_min" format="mm:ss"
                                            value-format="mm:ss" placeholder="Please select" />
                                            <img src="../assets/image/bg_14.png" class="w-14 h-28 m-l-5" alt="">
                                        </div>
                                    </div>
                                    <div class="d-flex-cen ju-sa cup">
                                        <div class="f-s-28 bold co_5C82FF dd-flex m-t-18" @click="settingsSaveClick(i)">Save</div>
                                        <div class="f-s-28 bold co_FF4040 dd-flex m-t-18" @click="settingsSaveDelet(item.id)">Delet</div>
                                    </div>
                                </div>
                            </div>

                            <div class="dd-flex p-b-20">
                                <el-button type="warning" size="large" class="m-t-20 f-s-26 h-50" @click="stepListAdd" style="width: 80%;" round>Add Steps</el-button>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
       
            <!-- 员工列表 -->
            <div class="m-10" v-if="tabIndex == 2">
                <el-row >
                    <el-col :span="14">
                        <div class="bg_fff box_box" >
                            <div class="d-flex-cen ju-sp " style="border-bottom: 2px solid #F5F6FA;">
                                <div class="h-121  d-flex-cen bg_fff " >
                                    <div class="f-s-36 bold m-l-28">Staff LIst</div>
                                    <img src="../assets/image/bg_12.png" @click="addUser" class="w-126 h-53 m-l-32" alt="">
                                </div>
                                <div class="d-flex-cen w-341 p-l-18" style="border-left: 2px solid #eee;">
                                    <img src="../assets/image/bg_17.png" class="wh-43 m-r-4" alt="">
                                    <el-input v-model="nickname" @input="getUserList" class="f-s-28 h-121 textClass" placeholder="Search staff" />
                                </div>
                            </div>
                            <div class="" style="overflow-y: auto;height: 72vh;">
                                <div class="p-28 por" :class="formValue.id == item.id?'bg_F0F6FF':''" style="border-bottom: 2px solid #F5F6FA;" :key="i" v-for="(item,i) in userList">
                               <div class="d-flex-cen ju-sp">
                                    <div class="d-flex-cen ">
                                        <div class="f-s-32 co_333 bold m-r-18">{{item.nickname}}</div>
                                        <div class="bg_092DB8 border-r-7 w-204 h-46 dd-flex co_fff"  v-if="item.type == 1"> Store Manager</div>
                                        <div class="border-r-7 w-103 h-46 dd-flex co_fff" :class="item.status == 'normal'?'bg_5C82FF':'bg_CACACA'" v-if="item.type == 2">Admin</div>
                                        <div class="border-r-7 w-158 h-46 dd-flex co_fff" :class="item.status == 'normal'?'bg_5C82FF':'bg_CACACA'" v-if="item.type == 3">Employee</div>
                                    </div>
                                    <div class="d-flex-cen f-s-32 cup" v-if="item.edit == 1">
                                        <el-switch size="large" active-value="normal" @change="switchClick(item)" inactive-value="hidden" v-model="item.status" />
                                        <div class=" co_092DB8 m-l-43 m-r-43" @click="getUserinfo(item.id)">Details</div>
                                        <div class="co_FF4040" @click="deleteClick(item.id)">Delet</div>
                                    </div>
                               </div>
                                <div class="m-t-14 f-s-28 co_777">ID：{{item.id}}</div>
                                <div class="w-340 h-89 bg_FFF4F4 poa f-s-25 co_FF4040 box_box" v-if="item.type == 1 && i != 0" style="padding: 9px 18px;right: 0;top: 0;">{{ `For password changes, \n contact the admin.` }}</div>
                            </div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="10">
                        <div class=" bg_fff " style="border-left: 1px solid #F5F6FA;">
                            <div class="f-s-36 co_111 bold h-121 d-flex-cen p-l-28 " style="border-bottom: 2px solid #F5F6FA;">Information</div>
                            
                            <div class="d-flex-cen ju-sp h-82 m-l-28 m-r-28 border-b-F5F6FA">
                                <span class="f-s-28 bold">Name</span>
                                <el-input
                                v-model="formValue.nickname"
                                class="f-s-28"
                                placeholder="Please enter Name"
                                
                                />
                            </div>
                            <div class="d-flex-cen ju-sp h-82 m-l-28 m-r-28 border-b-F5F6FA">
                                <span class="f-s-28 bold m-r-20">Account</span>
                                <el-input
                                v-model="formValue.account"
                                :disabled="formValue.id"
                                class="f-s-28 h-82"
                                placeholder="Please enter Account"
                                
                                />
                            </div>
                            <div class="d-flex-cen ju-sp h-82 m-l-28 m-r-28 border-b-F5F6FA">
                                <span class="f-s-28 bold">Password</span>
                                <el-input
                                v-model="formValue.password"
                                class="f-s-28 "
                                type="password"
                                show-password
                                placeholder="Please enter Password"
                                />
                            </div>
                            <div class="d-flex-cen ju-sp h-82 m-l-28 m-r-28 border-b-F5F6FA">
                                <span class="f-s-28 bold">Status</span>
                                <div class="d-flex-cen">
                                    <span class="f-s-28 co_666" >{{ formValue.status == 'normal'?'Enable':'Closure'}} </span>
                                    <el-switch size="large" active-value="normal" inactive-value="hidden" class="m-l-14" v-model="formValue.status" />
                                </div>
                            </div>
                            <div class="d-flex-cen ju-sp h-82 m-l-28 m-r-28 border-b-F5F6FA">
                                <span class="f-s-28 bold">Pole Assignment</span>
                                <el-radio-group v-model="formValue.type">
                                    <el-radio value="2"  size="large"><span class="f-s-28 co_666">Admin</span></el-radio>
                                    <el-radio value="3"  size="large"><span class="f-s-28 co_666" >Employee</span></el-radio>
                                </el-radio-group>
                            </div>

                        </div>
                        <div class="dd-flex">
                            <el-button type="primary" @click="userSublit" size="large" class="m-t-20 f-s-28" style="width: 80%;" round>Save</el-button>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
       </div>
    </div>
</template>

<script setup>
    import common from '../assets/common'
    import { ref , onMounted, onUnmounted } from "vue";
    import { useRouter } from 'vue-router'
    import { ElMessage, ElMessageBox } from 'element-plus'
  const router = useRouter()
  const tabIndex = ref('0')
  let startTime = ref('')
  let nickname = ref('')

    let userInfo = JSON.parse(localStorage.getItem('myData'));

    let leftList = ref([
        {name:'Workbench',img:''},
        {name:'Pot\n Management',img:''},
        {name:'Employee\n Management',img:''},
    ])
    
    //   左侧tab切换
    let tabClcik = (i) => {
        tabIndex.value = i
        if(i == 0) {
            getWorkbenchesList()
        }else if(i == 1) {
            getPotList()
        }else {
            getUserList()
        }
    }

    const usTime = ref('');
 
    const getUSDateTime = () => {
        const date = new Date();
        const timezone = 'America/New_York'; // 以纽约时区为例
        const formattedDate = new Intl.DateTimeFormat('en-US', {
            timeZone: timezone,
            hour12: false,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        }).format(date);
        
        usTime.value = formattedDate;

        let v1 = usTime.value.split(',')
          workbenchesList.value.forEach(e => {
            if((e.times[e.times_index]+':00').trim() == v1[1].trim()) {
                console.log(666,e)
                getResetData(e.times[e.times_index],e.step[0].id)
            }
        })

    };
 
    // 工作台列表
    let total = ref(0)
    let workbenchesList = ref([])
    let getWorkbenchesList = () => {
        common.httpFetch('api/index/workbenches','POST',{limit: fullscreen.value?5:4,page:page.value}).then(res => {
            if(res) {
                workbenchesList.value = res.data
                total.value = res.total
            }
        })
    } 

    // 重新开始（开始）
    let getResetData = (times,id) =>{
        common.httpFetch('api/index/reset','POST',{times,id}).then(() => {
            getWorkbenchesList()
        })
    }

    // 手动重新开始（开始）
    let restartClick = (times,id) => {
        ElMessageBox.confirm(
            'Do you confirm to restart?',
            'Warning',
            {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning',
            }
        ).then(() => {
            common.httpFetch('api/index/reset','POST',{times,id}).then(() => {
            getWorkbenchesList()
        })
           
        }).catch(() => {
            ElMessage({
                type: 'info',
                message: 'Delete canceled',
            })
        })
       
    }

    // 下一步
    let workGetClick = (times,step) => {
        let id = ''
        step.forEach((e) => {
            if(e.state == 1) {
                id = e.id
            }
        })
        common.httpFetch('api/index/next','POST',{times,id}).then(() => {
            getWorkbenchesList()
        })
    }

    // 全部完成
    let workEndSublit = (times,step) => {
        let id = ''
        step.forEach((e) => {
            if(e.state == 1) {
                id = e.id
            }
        })
        common.httpFetch('api/index/end','POST',{times,id}).then(() => {
            getWorkbenchesList()
        })
    }


    // 分页切换
    let page = ref('')
    let currentChange = (e) => {
        page.value = e
        getWorkbenchesList()
    }

    // -------------------工作台接口----------------------


    //   锅具列表
    let potList = ref([])
    let getPotList = () => {
        common.httpFetch('api/index/pot','POST').then(res => {
            if(res) {
                potList.value = res.data
            }
        })
    }

    // 锅具添加
    let addPot = () => {
        potObj.value = {times2:[],days2:[],type:'1',switch:1}
        stepList.value = []
    }

    // 锅具详情
    let potObj = ref({times2:[],days2:[],type:'1',switch:1})
    let stepList = ref([])
    let potInfoClick = (id) => {
        common.httpFetch('api/index/potInfo','POST',{id}).then(res => {
            if(res) {
                potObj.value = res.pot
                potObj.value.times2 = potObj.value.times
                potObj.value.days2 = potObj.value.days
                stepList.value = res.step
            }
        })
    }

    // 锅具删除
    let potDeletClick = (id) => {
        ElMessageBox.confirm(
            'Are you sure to delete the current cooker?',
            'Warning',
            {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning',
            }
        ).then(() => {
            common.httpFetch('api/index/potDel','POST',{id}).then(() => {
                ElMessage({
                        type: 'success',
                        message: 'Delete completed',
                    })
                getPotList()
            })
           
        }).catch(() => {
            ElMessage({
                type: 'info',
                message: 'Delete canceled',
            })
        })
    }

    // 锅具保存
    let potSaveClick = () => {
        potObj.value.times =  potObj.value.times2.toString()
        potObj.value.days =  potObj.value.days2.toString()
        common.httpFetch('api/index/potEdit','POST',potObj.value).then(res => {
            if(res) {
                ElMessage({
                    message: 'Saved successfully',
                    type: 'success',
                    plain: true,
                })
                getPotList()
                potObj.value = {times2:[],days2:[],type:'1',switch:1}
                stepList.value = []
            }
        })
    }

    // 时间选择器
    let datePickerChang = (e) => {
        console.log(e)
    }

    // 步骤添加编辑
    let settingsSaveClick = (i) =>{
        common.httpFetch('api/index/stepEdit','POST',stepList.value[i]).then(res => {
            if(res) {
                ElMessage({
                    message: 'Saved successfully',
                    type: 'success',
                    plain: true,
                })
                potInfoClick(potObj.value.id)
                getPotList()
            }
        })
    }

    // 步骤删除
    let settingsSaveDelet = (id) => {
        ElMessageBox.confirm(
            'Do you confirm to delete the current step?',
            'Warning',
            {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning',
            }
        ).then(() => {
            common.httpFetch('api/index/stepDel','POST',{id}).then(() => {
                ElMessage({
                        type: 'success',
                        message: 'Delete completed',
                    })
                getPotList()
                potInfoClick(potObj.value.id)
            })
           
        }).catch(() => {
            ElMessage({
                type: 'info',
                message: 'Delete canceled',
            })
        })
    }

    // 选择开始时间
    let timeSelectClick = (e) =>{

        if(!potObj.value.times2.includes(e)) {
            potObj.value.times2.push(e)
        }

    }

    // 删除开始时间
    let tagClose = (e) => {
        potObj.value.times2.forEach((k,i) => {
            if(k == e) {
                potObj.value.times2.splice(i,1)
            }
        })
    }

    // 步骤添加默认数据
    let stepListAdd = () => {
        stepList.value.push({
            pot_id:potObj.value.id,
            title:'',
            tips:'',
            min:'',
            force_min:'',
        })
    }

   


    // ------------------------- 锅具接口  --------------------------------


    //   获取员工管理
    let userList = ref([])
    let getUserList = () => {
        common.httpFetch('api/index/userList','POST',{nickname:nickname.value}).then(res => {
            if(res) {
                userList.value = res
            }
        })
    }

    //   员工详情
    let formValue = ref({})
    let getUserinfo = (id) => {
        common.httpFetch('api/index/userinfo','POST',{id}).then(res => {
            if(res) {
                formValue.value = res
                formValue.value.account = res.username
                formValue.value.id = id
            }
        })
    }

    // 添加员工
    let addUser = () => {
        formValue.value = {}
    }

    // 添加编辑员工
    let userSublit = () => {
        common.httpFetch('api/index/userEdit','POST',formValue.value).then(res => {
            if(res) {
                ElMessage({
                    message: 'Saved successfully',
                    type: 'success',
                    plain: true,
                })
                getUserList()
                // formValue.value = {}
            }
        })
    }

    // 列表切换员工状态
    let switchClick = (e) => {
        console.log(e)
        formValue.value = e
        formValue.value.account = e.username
        userSublit()
    }

    // 删除员工
    const deleteClick = (id) => {
        ElMessageBox.confirm(
            'Do you confirm to delete the employee?',
            'Warning',
            {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning',
            }
        ).then(() => {
            common.httpFetch('api/index/userDel','POST',{id}).then(() => {
                ElMessage({
                        type: 'success',
                        message: 'Delete completed',
                    })
                getUserList()
            })
           
        }).catch(() => {
            ElMessage({
                type: 'info',
                message: 'Delete canceled',
            })
        })
    }

    

    // --------------------------- 员工接口 -------------------------------------

  let fullscreen = ref('')

 
  const toggleFullScreen = () => {
  if (!document.fullscreenElement && !fullscreen.value) {
    // 进入全屏
    document.documentElement.requestFullscreen().then(() => {
        fullscreen.value = true;
        getWorkbenchesList()
    });
  } else {
    // 退出全屏
    if (document.exitFullscreen) {
      document.exitFullscreen().then(() => {
        fullscreen.value = false;
        getWorkbenchesList()
      });
    }
  }
};

 onMounted(() => {
    setInterval(() => {
        getUSDateTime()
    },1000)
    getWorkbenchesList()
    window.addEventListener("resize", clickHandle);
});
 
onUnmounted(() => {
  window.removeEventListener('keydown', clickHandle);
});


let isShowScreen = ref('')
// 点击开启全屏按钮事件
const clickHandle = () => {
    isShowScreen.value = true;
    const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement || ( window.innerHeight === screen.height);
    if(fullscreenElement) {
        // console.log('全屏了')
        fullscreen.value = true
        getWorkbenchesList()
    }else {
        fullscreen.value = false
        getWorkbenchesList()
        // console.log('退出来了')
    }
  };
  let goToAbout = () => {
    localStorage.removeItem('myData')
    router.push('/')
  }

</script>


<style >
.el-pagination.is-background .el-pager li.is-active {
    background-color: #092DB8 !important;
}
.el-pagination.is-background .btn-prev{
    background-color: rgba(0, 42, 183, 0.40) !important;
    color: #fff !important;
}
.el-pagination.is-background .btn-next {
    background-color: rgba(0, 42, 183, 0.40) !important;
    color: #fff !important;
}

.el-input__prefix{
    cursor: pointer;
}


.el-pagination.is-background .el-pager li{
    border: 1px solid #092DB8;
    background-color: transparent;
    color: #092DB8;
}

.el-input__wrapper{
    box-shadow: none !important;
    padding-left: 0 !important;
  }
  .el-input__inner{
    text-align: right !important;
  }
  .el-radio.el-radio--large .el-radio__inner{
    width: 22px !important;
    height: 22px !important;
  }
  .textClass .el-input__inner{
    text-align: left !important;
    
  }
  
</style>

<style lang="scss">
body{
    background: #F5F6FA;
}
.logutClass{
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translate(-50%,0);
}
.biankang{
    width: 2px;
    height: 43px;
    border-radius: 0px 0px 0px 0px;
    border: 1px solid #CACACA;
}
.nextClass{
    width: 180px;
    height: 75px;
    background: #F0F6FF;
    border-radius: 0px 0px 0px 0px;
}
.finishClass{
    width: 180px;
    height: 75px;
    background: #5C82FF;
    border-radius: 0px 0px 0px 0px;
}
.timeClass{
    right: 0;
    top: 0;
}


.border-b-F5F6FA{
    border-bottom:2px solid #F5F6FA;
}


.leftClass{
    position: fixed;
    left: 0;
    top: 0;
    width: 224px;
    height: 100vh;
    z-index: 10;
}

.topClass{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    // background: #fff;
    flex: 1;
    display: flex;
    z-index: 9;
}

.onPotClass{
    background: #F0F6FF !important;
    border: 2px solid #5C82FF;
}

.minh_96{
    min-height: 96px;
}
</style>