<template>
	<div class="content">
		<div class="boxClass">
			<div class="m-auto text-cen m-t-107">
        <img src="../assets/image/bg_2.png" class="wh-178" alt="">
        <div class="f-s-57 co_000 m-t-20" @click="goToAbout">YAAAS TEA</div>
      </div>
      <div class="m-t-57">
        <div class="inputClass m-auto d-flex-cen">
          <img src="../assets/image/bg_18.png" class="wh-46 m-l-21 m-r-14" alt="">
          <el-input v-model="account" :border="false" style="width: 100%;" class="h-100 fff f-s-32" placeholder="Enter username" />
        </div>
        <div class="inputClass m-auto d-flex-cen m-t-43">
          <img src="../assets/image/bg_19.png" class="wh-46 m-l-21 m-r-14" alt="">
          <el-input v-model="password" type="password" :border="false" show-password style="width: 100%;" class="h-100 fff f-s-32" placeholder="Enter password" />
        </div>
      </div>
      <div class="m-t-71 buttonClass m-auto f-s-32 dd-flex co_fff cup" @click="sublit">Log In</div>
		</div>
	</div>
</template>

<script setup>
  import common from '../assets/common'
	import { ref } from "vue";
  import { useRouter } from 'vue-router'
  import { ElMessage } from 'element-plus'
  const router = useRouter()
  const account = ref('')
  const password = ref('')

  let goToAbout = () => {
    // router.push('/abouttt')
    router.push({ path: '/about', hash: '#team' })
  }

  let sublit = () => {
      if(account.value == '') {
        return ElMessage.error('Enter username')
      }
      if(password.value == '') {
        return ElMessage.error('Enter password')
      }

      common.httpFetch('api/user/login','POST',{
        account: account.value,
        password:password.value
      }).then(res => {
        if(res) {
          console.log(res,9999)
        localStorage.setItem('myData', JSON.stringify(res.userinfo));
        router.push('/about')
        }
        
      })
  }

</script>

<style>
  .fff .el-input__inner{
    text-align: left !important;
    height: 100% !important;
  }
</style>
<style lang="scss" scoped>
	.content {
    position: relative;
		background: url('../assets/image/bg_1.png') no-repeat center center;
		background-size: 1920px 100vh;
		width: 1920px;
		height: 100vh;
	}

	.boxClass{
    position: absolute;
    width: 782px;
    height: 964px;
    background: #fff;
		right: 107px;
		top: 50%;
		transform: translate(0,-50%);
    box-shadow: 0px 7 9px 0px rgba(230,235,255,0.2);
    border-radius: 43px 43px 43px 43px;
	}

  .inputClass{
    width: 640px;
    height: 100px;
    background: #FFFFFF;
    border-radius: 7px 7px 7px 7px;
    border: 2px solid #E4E4E4;
  }

  :deep .el-input__wrapper{
    box-shadow: none !important;
  }

  .buttonClass{
    width: 640px;
    height: 101px;
    background: #092DB8;
    border-radius: 16px 16px 16px 16px;
  }
</style>
