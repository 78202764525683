
<template>
    <div class="slider-container">
      <div
        class="slider"
        :style="{ left: sliderLeft + 'px' }"
        @mousedown="handleMouseDown"
        @touchstart="handleTouchStart"
      >
        <div class="slider-button"></div>
      </div>
    </div>
  </template>
   
  <script setup>
  import { ref, onMounted, onBeforeUnmount } from 'vue';
   
  const sliderLeft = ref(0);
  let startX = 0;
  let containerWidth = 0;
   
  const handleMouseDown = (event) => {
    startX = event.clientX - sliderLeft.value;
    containerWidth = event.currentTarget.parentNode.offsetWidth;
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };
   
  const handleMouseMove = (event) => {
    const x = event.clientX - startX;
    sliderLeft.value = Math.min(Math.max(0, x), containerWidth - event.currentTarget.offsetWidth);
  };
   
  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };
   
  const handleTouchStart = (event) => {
    startX = event.touches[0].clientX - sliderLeft.value;
    containerWidth = event.currentTarget.parentNode.offsetWidth;
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);
  };
   
  const handleTouchMove = (event) => {
    const x = event.touches[0].clientX - startX;
    sliderLeft.value = Math.min(Math.max(0, x), containerWidth - event.currentTarget.offsetWidth);
  };
   
  const handleTouchEnd = () => {
    document.removeEventListener('touchmove', handleTouchMove);
    document.removeEventListener('touchend', handleTouchEnd);
  };
   
  onMounted(() => {
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('touchstart', handleTouchStart);
  });
   
  onBeforeUnmount(() => {
    document.removeEventListener('mousedown', handleMouseDown);
    document.removeEventListener('touchstart', handleTouchStart);
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
    document.removeEventListener('touchmove', handleTouchMove);
    document.removeEventListener('touchend', handleTouchEnd);
  });
  </script>
   
  <style scoped>
  .slider-container {
    position: relative;
    width: 100%;
    height: 20px;
    background-color: #e5e5e5;
    border-radius: 10px;
  }
   
  .slider {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 20px;
    background-color: #1976d2;
    border-radius: 10px;
    cursor: pointer;
    user-select: none;
  }
   
  .slider-button {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    user-select: none;
  }
  </style>