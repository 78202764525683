<template>
   <RouterView />
</template>

<script>

export default {
  name: 'App',
 
}
</script>

<style>
#app {

}
body{
  margin: 0;
  padding: 0;
}
</style>
