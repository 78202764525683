import {ElMessage} from 'element-plus'
let baseUrl= 'https://julong.yilidakeji.com/'
export default {
    async httpFetch(url,method,body) {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8'
        }
        if(localStorage.getItem('myData') && JSON.parse(localStorage.getItem('myData')).token) {
            // console.log(9999)
            headers.token = JSON.parse(localStorage.getItem('myData')).token
        }
      

        let response  = await fetch(baseUrl+url, {
            method,
            headers,
            body: JSON.stringify(body),
        });

        let resValue = await response.json()
        if(resValue.code == 0) {
            ElMessage.error(resValue.msg)
            return
        }else {
            return  resValue.data
        }
        
    }
}