import { createWebHistory, createRouter } from 'vue-router'

import HomeView from '../components/HelloWorld.vue'
import AboutView from '../components/AboutView.vue'
import demoView from '../components/houtaiDemo.vue'

const routes = [
  { path: '/', component: HomeView },
  { path: '/about', component: AboutView },
  { path: '/houtaiDemo', component: demoView }, 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router